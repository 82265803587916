@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(./assets/fonts/Quicksand-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: url(./assets/fonts/Lato-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(./assets/fonts/Lato-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url(./assets/fonts/Lato-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url(./assets/fonts/Lato-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}


html, body, #root {
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0
}